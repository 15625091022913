import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {Component} from 'react';
import process from 'process';
import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Work from "./pages/Work";


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/skills" component={Skills} />
            <Route path="/projects" component={Projects} />
            <Route path="/work" component={Work} />
            <Route path="/contact" component={Contact} />
        </Switch>
       </BrowserRouter>
    );
  }
}

export default App;
